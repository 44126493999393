export default [
  {
    header: 'Dashboard',
  },
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Clients',
    route: 'client-select',
    icon: 'PieChartIcon',
  },
  {
    header: 'Audit',
  },
  {
    title: 'Log Details',
    route: 'view-log',
    icon: 'HardDriveIcon',
  },
  {
    header: 'Subscription',
  },
  {
    title: 'All Subscriptions',
    route: 'package-suscription',
    icon: 'AwardIcon',
  },
  {
    title: 'Packages',
    route: 'all-packages',
    icon: 'GiftIcon',
  },
  {
    header: 'Settings ',
  },
  {
    title: 'Settings',
    route: 'business-settings',
    icon: 'SettingsIcon',
  },
]
